import './style.scss'
import { eventNames } from 'cluster';

function addEventListeners(el, events, listener) {
  events.split(' ').forEach(e => el.addEventListener(e, listener, false));
}

function render(image, callback) {
  const offset = Math.max(image.width, image.height) * 0.1;
  const imgWidth = image.width + offset * 2;
  const imgHeight = image.height + offset * 2;

  const canvas = document.createElement('canvas');
  canvas.width = imgWidth;
  canvas.height = imgHeight;

  const ctx = canvas.getContext('2d');
  ctx.shadowColor = 'black';
  ctx.shadowBlur = offset * 0.8;
  ctx.drawImage(image, offset, offset);

  canvas.toBlob( blob => {
    callback(URL.createObjectURL(blob));
  }, 'image/png');
}

function isImage(file) {
  return file.type.match('^image/.*');
}

document.addEventListener('DOMContentLoaded', () => {
  const title = document.querySelector('.title');
  const uploadbox = document.querySelector('.upload-box');
  const instruction = document.querySelector('.instruction');
  const resultbox = document.querySelector('.result-box');
  const imagesContainer = document.querySelector('.result-box .images');

  addEventListeners(
    uploadbox,
    'drag dragstart dragend dragover dragenter dragleave drop',
    e => {
      e.preventDefault();
      e.stopPropagation();
    }
  );
  addEventListeners(uploadbox, 'dragover dragenter', () => {
    uploadbox.classList.add('is-dragover');
  });
  addEventListeners(uploadbox, 'dragleave dragend drop', () => {
    uploadbox.classList.remove('is-dragover');
  });

  const load = file => {
    resultbox.classList.add('has-results');
    uploadbox.classList.add('has-results');
    title.classList.add('hide');
    instruction.innerHTML = 'Drag new image(s) here';

    const reader = new FileReader();
    reader.onload = e => {
      const image = new Image();
      image.onload = () => {
        render(image, shadowed => {
          const img = document.createElement('img');
          img.src = shadowed;
          const imgWrapper = document.createElement('div');
          imgWrapper.classList.add('checkers');
          imgWrapper.appendChild(img);

          const download = document.createElement('a');
          download.setAttribute('href', shadowed);
          download.setAttribute('download', file.name);
          download.className = 'action download';
          download.addEventListener('click', (evt) => {
            download.classList.add('clicked');
            setTimeout(() => {
              download.classList.remove('clicked');
            }, 1200);
          });
          imgWrapper.appendChild(download);

          imagesContainer.appendChild(imgWrapper);
        });
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  uploadbox.addEventListener('drop', e => {
    imagesContainer.innerHTML = '';
    resultbox.classList.remove('has-results');
    uploadbox.classList.remove('has-results');

    if (e.dataTransfer) {
      Array.from(e.dataTransfer.files).forEach(file => {
        if (isImage(file)) {
          load(file);
        }
      });
    }
  });

  document.addEventListener('paste', e => {
    e.preventDefault();
    if (e.clipboardData) {
      Array.from(e.clipboardData.items).forEach(item => {
        if (isImage(item)) {
          load(item.getAsFile());
        }
      });
    }
  });
});
